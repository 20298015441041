import "../styles/footer.css"

function Copyright() {
  return (
    <div class="copyright_wrap">
      <p class="copyright">
        &copy;2021 <a href="https://zzzsy.top/">Zhang.Mathias</a> All rights
        reserved. |{" "}
        <a href="https://creativecommons.org/licenses/by-nc/4.0/">
          CC BY-NC 4.0
        </a>{" "}
        | <a href="https://beian.miit.gov.cn">辽ICP备2021008388-1号 | </a>{" "}
        Powered by{" "} 
        <a href="https://vercel.com" target="_blank" rel="noreferrer">
          Vercel
        </a>
      </p>
    </div>
  );
}

export default Copyright;
