import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import Copyright from "./components/copyright";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

ReactDOM.render(
    <BrowserRouter>
      <Copyright/>
    </BrowserRouter>,
  document.getElementsByClassName("footer")
);


reportWebVitals();
