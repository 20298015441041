import "./App.css";

import { Routes, Route } from "react-router-dom";
import Home from "./components/home";
import Notfound from "./components/notfound"
import Note from './components/viewer';


function App() {
  return (
    <div className="main">
      <Routes>
        <Route path="/notes/:id" element={<Note />}/>
        <Route path="/" element={<Home />}/>
        <Route path="*" element={<Notfound />}/>
      </Routes>
    </div>
  );
}

export default App;
