import React from "react";
import { useParams } from "react-router-dom";

import PDFViewer from "./PDFViewer";
import NotFound from "./notfound";
import PDFJSBackend from "../backends/pdfjs";
import uris from "../config";

const Note = () => {
  let { id } = useParams();
  let uri = uris[id];
  if (uri) {
    let f = document.getElementById("f");
    f.style.display="none"
    document.title = `ZSYのNotes｜${id}`;
    return (
      <div className="Viewers">
        <PDFViewer backend={PDFJSBackend} src={uri} />
      </div>
    );
  } else{
    return (<NotFound />)
  }
};

export default Note;
