import { Link } from "react-router-dom";

function notFound(){
  let f = document.getElementById("f");
  f.style.display="block"
  return (
    <div className="notFound">
      <h2>你来到了没有知识的荒原～</h2>
      <p>
        <Link to="/">返回</Link>
      </p>
    </div>
  )
}

export default notFound;
