import { Link } from "react-router-dom";

function home(){
  let f = document.getElementById("f");
  f.style.display="block"
  return (<div className="container">
  <Link to="/" className="Title"><h1>ZSY の Notes</h1></Link>
  <p>
    <Link to="/notes/ana-2">Analyse-2</Link>
  </p>
  <p>
    <Link to="/notes/alg-1">Algèbre-1</Link>
  </p>
  <p>
    <Link to="/notes/geom-1">Géométrie-1</Link>
  </p>
  <p>
    <Link to="/notes/elec-1">Électrocinétique-1</Link>
  </p>
</div>
)
}

export default home;
