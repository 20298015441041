export default class PDFJs {
    init = (source, element) => {
      const iframe = document.createElement('iframe');
      iframe.src = `/pdfjs/web/viewer.html?file=${source}`;
      iframe.width = '100%';
      iframe.height = '100%';
      iframe.frameborder="0";
      iframe.style.display="block"
      element.appendChild(iframe);
    }
  }